export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "sprunki rejoyed",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://iframegame.com/embed/sprunki-rejoyed/index.html",
    domain: "sprunkirejoyed.com",
    gaId: "G-ZPD87VJHJL",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
